const ChatIcon = (props) => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0.015625C18.5625 0.015625 19.0312 0.21875 19.4062 0.625C19.8125 1 20.0156 1.45313 20.0156 1.98438V13.9844C20.0156 14.5469 19.8125 15.0312 19.4062 15.4375C19.0312 15.8125 18.5625 16 18 16H3.98438L0 19.9844V1.98438C0 1.45313 0.1875 1 0.5625 0.625C0.96875 0.21875 1.45312 0.015625 2.01562 0.015625H18Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChatIcon;
