const YoutubeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480 480"
      xmlSpace="preserve"
      version="1.1"
      id="svg3390"
      {...props}
    >
      <defs id="defs3394">
        <clipPath id="clipPath3404" clipPathUnits="userSpaceOnUse">
          <path id="path3406" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3416" clipPathUnits="userSpaceOnUse">
          <path id="path3418" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3426" clipPathUnits="userSpaceOnUse">
          <path id="path3428" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3434" clipPathUnits="userSpaceOnUse">
          <path id="path3436" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3442" clipPathUnits="userSpaceOnUse">
          <path id="path3444" d="M0 48h48V0H0v48z" />
        </clipPath>
        <clipPath id="clipPath3446" clipPathUnits="userSpaceOnUse">
          <path id="path3448" d="M0 48h48V0H0v48z" />
        </clipPath>
        <clipPath id="clipPath3450" clipPathUnits="userSpaceOnUse">
          <path id="path3452" d="M48 48H0V0h48v48Z" />
        </clipPath>
        <clipPath id="clipPath3658" clipPathUnits="userSpaceOnUse">
          <path id="path3660" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3668" clipPathUnits="userSpaceOnUse">
          <path id="path3670" d="M0 48h48V0H0v48z" />
        </clipPath>
        <clipPath id="clipPath3676" clipPathUnits="userSpaceOnUse">
          <path id="path3678" d="M0 48h48V0H0v48z" />
        </clipPath>
        <clipPath id="clipPath3680" clipPathUnits="userSpaceOnUse">
          <path id="path3682" d="M0 0h48v48H0V0Z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.25 0 0 -1.25 0 480)" id="g3398">
        <path
          d="M348.806 255.672s-3.123 21.95-12.723 31.614c-12.166 12.682-25.811 12.746-32.064 13.49C259.24 304 192.07 304 192.07 304h-.14s-67.168 0-111.95-3.223c-6.252-.745-19.89-.803-32.063-13.491-9.594-9.663-12.717-31.614-12.717-31.614S32 229.9 32 204.128v-24.16c0-25.773 3.2-51.545 3.2-51.545s3.123-21.95 12.717-31.613C60.09 84.12 76.077 84.523 83.2 83.198 108.8 80.752 192 80 192 80s67.238.102 112.02 3.325c6.252.745 19.89.803 32.063 13.491 9.594 9.663 12.723 31.614 12.723 31.614S352 154.202 352 179.974v24.16c0 25.773-3.194 51.545-3.194 51.545zm-113.715-66.207-.006.006-75.82-39.098v89.49l86.463-44.901-10.636-5.498z"
          id="path4521"
          fill="currentColor"
        />
      </g>
      <style type="text/css" id="style3999" />
    </svg>
  );
};

export default YoutubeIcon;
