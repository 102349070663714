const ChevronRightIcon = (props) => {
  return (
    <svg
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.4375 6.25L8.96875 7.71875L13.7396 12.5L8.96875 17.2812L10.4375 18.75L16.6875 12.5L10.4375 6.25Z"
      />
    </svg>
  );
};

export default ChevronRightIcon;
