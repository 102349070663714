import FacebookIcon from '../icons/facebookIcon';
import InstagramIcon from '../icons/instagramIcon';
import LinkedinLogo from '../icons/linkedinIcon';
import TwitterIcon from '../icons/twitterIcon';
import YoutubeIcon from '../icons/youtubeIcon';
import Red113Logo from '../red113Logo';
import HeadsUpText from '../headsUpText';
import style from './style.module.css';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.footerWrapper}>
        <div className={style.logoWrapper}>
          <Red113Logo />
        </div>

        <div className={style.contentWrapper}>
          <p className={style.contactParagraph}>
            Wil je meer weten?
            <br /> Neem dan{' '}
            <a
              href="https://www.113.nl/contact-met-113"
              className="inline-link underline"
            >
              contact
            </a>{' '}
            met ons op.
          </p>

          <div className={style.headsUpWrapper}>
            <HeadsUpText />
          </div>
        </div>

        <div className={style.navWrapper}>
          <nav className={style.socialNav}>
            <p className={style.socialNavParagraph}>Volg ons op</p>

            <ul className={style.socialLinkList}>
              <li>
                <a
                  href="https://www.facebook.com/113Zelfmoordpreventie"
                  className={style.socialIcon}
                >
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/113zelfmoordpreventie"
                  className={style.socialIcon}
                >
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/113zelfmoordpreventie"
                  className={style.socialIcon}
                >
                  <LinkedinLogo />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/113preventie"
                  className={style.socialIcon}
                >
                  <TwitterIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCrJhprcKwz3ZNmUgTa7GstA"
                  className={style.socialIcon}
                >
                  <YoutubeIcon />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
