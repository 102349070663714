import style from './style.module.css';

const StepsList = ({ steps }) => {
  return (
    <ul className={style.stepsList}>
      {steps.map((step, index) => (
        <li key={index} className={style.stepWrapper}>
          <div className={style.stepNumber}>{index + 1}</div>
          <div className={style.stepContent}>{step}</div>
        </li>
      ))}
    </ul>
  );
};

export default StepsList;
