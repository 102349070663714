const TwitterIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M17.71 9.33c-.07 4.62-3.02 7.78-7.43 7.98-1.82.08-3.13-.5-4.28-1.23 1.34.21 3-.32 3.9-1.08-1.32-.14-2.09-.81-2.46-1.88.38.06.78.04 1.14-.03-1.19-.4-2.04-1.14-2.08-2.68.33.16.68.3 1.14.33-.89-.51-1.54-2.36-.79-3.58 1.32 1.45 2.91 2.63 5.52 2.79-.66-2.8 3.05-4.32 4.6-2.45.66-.12 1.19-.36 1.71-.64-.21.64-.62 1.11-1.12 1.47.54-.07 1.03-.2 1.44-.41-.25.53-.81 1.01-1.29 1.41M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4a2 2 0 0 0-2-2Z"
      />
    </svg>
  );
};

export default TwitterIcon;
