const LinkedinLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480 480"
      xmlSpace="preserve"
      version="1.1"
      id="svg3390"
      {...props}
    >
      <defs id="defs3394">
        <clipPath id="clipPath3404" clipPathUnits="userSpaceOnUse">
          <path id="path3406" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3416" clipPathUnits="userSpaceOnUse">
          <path id="path3418" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3426" clipPathUnits="userSpaceOnUse">
          <path id="path3428" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3434" clipPathUnits="userSpaceOnUse">
          <path id="path3436" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3442" clipPathUnits="userSpaceOnUse">
          <path id="path3444" d="M0 48h48V0H0v48z" />
        </clipPath>
        <clipPath id="clipPath3446" clipPathUnits="userSpaceOnUse">
          <path id="path3448" d="M0 48h48V0H0v48z" />
        </clipPath>
        <clipPath id="clipPath3450" clipPathUnits="userSpaceOnUse">
          <path id="path3452" d="M48 48H0V0h48v48Z" />
        </clipPath>
        <clipPath id="clipPath3658" clipPathUnits="userSpaceOnUse">
          <path id="path3660" d="M0 48h48V0H0v48Z" />
        </clipPath>
        <clipPath id="clipPath3668" clipPathUnits="userSpaceOnUse">
          <path id="path3670" d="M0 48h48V0H0v48z" />
        </clipPath>
        <clipPath id="clipPath3676" clipPathUnits="userSpaceOnUse">
          <path id="path3678" d="M0 48h48V0H0v48z" />
        </clipPath>
        <clipPath id="clipPath3680" clipPathUnits="userSpaceOnUse">
          <path id="path3682" d="M0 0h48v48H0V0Z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.25 0 0 -1.25 0 480)" id="g3398">
        <path
          d="M271.134 187.916c-4.109 5.136-11.494 7.711-22.17 7.711-13.613 0-23.162-4.01-28.666-12.03-5.504-8.022-8.237-19.177-8.237-33.5V40.169c0-2.17-.813-4.075-2.438-5.71-1.626-1.635-3.59-2.453-5.908-2.453h-57.089c-2.163 0-4.057.818-5.683 2.453-1.626 1.635-2.438 3.54-2.438 5.71v202.598c0 2.176.812 4.088 2.438 5.723 1.626 1.628 3.52 2.446 5.683 2.446h55.47c1.997 0 3.63-.393 4.877-1.178 1.235-.766 2.042-2.215 2.439-4.307.364-2.105.601-3.501.69-4.203.065-.695.11-2.453.11-5.26 14.38 13.545 33.402 20.304 57.082 20.304 26.458 0 47.188-6.637 62.197-19.95C344.48 223.044 352 203.243 352 176.954V40.163c0-2.17-.813-4.075-2.432-5.71-1.626-1.635-3.52-2.453-5.69-2.453H285.4c-2.163 0-4.064.818-5.683 2.453-1.626 1.635-2.438 3.54-2.438 5.71v123.471c0 11.053-2.042 19.125-6.145 24.275zM98.14 285.484c-7.597-7.628-16.724-11.439-27.399-11.439s-19.79 3.811-27.373 11.44C35.795 293.111 32 302.284 32 313.022c0 10.737 3.789 19.916 11.373 27.538S60.077 352 70.746 352c10.67 0 19.802-3.81 27.4-11.439 7.564-7.628 11.372-16.801 11.372-27.538 0-10.738-3.808-19.91-11.373-27.539zm9.267-42.717V40.169c0-2.17-.806-4.075-2.432-5.71-1.625-1.635-3.526-2.453-5.683-2.453H42.195c-2.163 0-4.057.818-5.677 2.453-1.619 1.635-2.438 3.54-2.438 5.71v202.598c0 2.176.813 4.088 2.438 5.723 1.626 1.628 3.52 2.446 5.677 2.446h57.096c2.163 0 4.064-.818 5.683-2.446 1.626-1.635 2.426-3.54 2.426-5.723z"
          id="path4140"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default LinkedinLogo;
