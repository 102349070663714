const InstagramIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M20 6.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5v-2c0-.28.22-.5.5-.5h2c.28 0 .5.22.5.5M4.5 20c-.28 0-.5-.22-.5-.5V11h2.09c-.06.32-.09.66-.09 1 0 3.31 2.69 6 6 6s6-2.69 6-6c0-.34-.04-.68-.09-1H20v8.5c0 .28-.22.5-.5.5M12 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4m8-6H4c-1.11 0-2 .89-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4a2 2 0 0 0-2-2Z"
      />
    </svg>
  );
};

export default InstagramIcon;
