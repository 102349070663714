import { useRef, useState, lazy, Suspense } from 'react';
import ChevronRightIcon from '../icons/chevronRightIcon';
import ArrowDownIcon from '../icons/arrowDownIcon';
import StepsList from '../stepsList';
import style from './style.module.css';

const ModalPortal = lazy(() => import('../modalPortal'));

const MainContent = () => {
  const [showModal, setShowModal] = useState(false);
  const openModalButtonRef = useRef(null);
  const readMoreRef = useRef(null);
  const mainRef = useRef(null);

  function openModalHandler() {
    setShowModal(true);
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  }

  function closeModalHandler() {
    setShowModal(false);
    document.body.style.overflow = 'unset';
    openModalButtonRef.current.focus();
  }

  return (
    <>
      <div className={style.topImageWrapper}>
        <img className={style.topImage} src="/images/113-header-2.jpg" alt="" />
        <div className={style.readMoreContainer}>
          {/* eslint-disable */}
          <a
            href="#"
            className={style.readMoreLink}
            ref={readMoreRef}
            onClick={(e) => {
              e.preventDefault();
              mainRef.current.scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <span>Lees meer</span>
            <ArrowDownIcon className={style.arrowDownIcon} />
          </a>
        </div>
      </div>
      <main ref={mainRef}>
        <section className={style.topSection}>
          <h1>Help de stilte doorbreken met de 113-fotofilter.</h1>
          <p>
            Het is tijd dat we gaan praten over onze problemen. Want praten kan
            levens redden. Help mee om het zwijgen te doorbreken: maak een foto
            van jezelf en blokkeer via het filter het woord &#8216;niet&#8217;.
            Deel de foto met je omgeving, en begin het gesprek.
          </p>
        </section>

        <section className={style.stepsSection}>
          <img
            className={style.stepsImage}
            src="/images/desktop-example.jpg"
            alt=""
          />
          <img
            className={style.stepsImageMobile}
            src="/images/mobile-example.jpg"
            alt=""
          />
          <div className={style.stepsWrapper}>
            <StepsList
              steps={[
                <p>
                  <span className={style.bold}>Upload een foto</span> van jezelf
                  waar je volledig op te zien bent. Vraag bijvoorbeeld iemand om
                  een foto van je te maken. Tip: Een rustige achtergrond maakt
                  de tekst leesbaarder.
                </p>,
                <p>
                  <span className={style.bold}>Positioneer de foto</span>, zodat
                  je voor het woord &#8216;niet&#8217; komt te staan. Zorg dat
                  je gezicht zichtbaar blijft.
                </p>,
                <p>
                  <span className={style.bold}>Download de foto</span> met
                  filter, en deel het met je omgeving.
                </p>,
              ]}
            />
            <button
              className={style.startButton}
              onClick={openModalHandler}
              ref={openModalButtonRef}
            >
              <ChevronRightIcon className={style.chevronIcon} />

              <span>Start nu</span>
            </button>
          </div>
        </section>
        <Suspense>
          <ModalPortal isOpen={showModal} onClose={closeModalHandler} />
        </Suspense>
      </main>
    </>
  );
};

export default MainContent;
