import './generic.css';
import './typography.css';

import Header from './components/header';
import Footer from './components/footer';
import MainContent from './components/mainContent';
import MobileCallChatModal from './components/mobileCallChatModal';

function App() {
  return (
    <div className="page-wrapper">
      <Header />

      <MainContent />

      <Footer />

      <MobileCallChatModal />
    </div>
  );
}

export default App;
