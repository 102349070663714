const ChevronUpIcon = () => {
  return (
    <svg viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 8.47505L2.2625 10.2375L8 4.51255L13.7375 10.2375L15.5 8.47505L8 0.975049L0.5 8.47505Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronUpIcon;
