const CloseIcon = (props) => {
  return (
    <svg
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7913 1.67712L13.3226 0.208374L7.49967 6.03129L1.67676 0.208374L0.208008 1.67712L6.03092 7.50004L0.208008 13.323L1.67676 14.7917L7.49967 8.96879L13.3226 14.7917L14.7913 13.323L8.96842 7.50004L14.7913 1.67712Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
