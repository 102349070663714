import { createContext, useContext, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';

const modalStates = ['NO_PHOTO', 'PHOTO_UPLOADED'];

const context = createContext(null);

export const StoreProvider = ({ children }) => {
  const [canShare, setCanShare] = useState(false);
  const [modalState, setModalState] = useState(modalStates[0]);
  const [photoTitle, setPhotoTitle] = useState('');
  const [photoDataURL, setPhotoDataURL] = useState(null);
  const [isMobileWidth, setIsMobileWidth] = useState(false);
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const [cropperInstance, setCropperInstance] = useState(null);

  const [ref, bounds] = useMeasure();

  useEffect(() => {
    const maxMobileScreenWidth = 1023;
    const { width } = bounds;
    if (width <= maxMobileScreenWidth) {
      setIsMobileWidth(true);
    } else {
      setIsMobileWidth(false);
    }
  }, [bounds]);

  useEffect(() => {
    if (navigator.canShare) {
      setCanShare(true);
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobileBrowser(true);
    } else {
      setIsMobileBrowser(false);
    }
  }, []);

  return (
    <context.Provider
      value={{
        modalState,
        setModalState,
        photoTitle,
        setPhotoTitle,
        photoDataURL,
        setPhotoDataURL,
        canShare,
        isMobileWidth,
        isMobileBrowser,
        cropperInstance,
        setCropperInstance,
      }}
    >
      <div id="useMeasureRef" ref={ref}>
        {children}
      </div>
    </context.Provider>
  );
};

export const useStore = () => useContext(context);
