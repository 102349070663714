import PhoneIcon from '../icons/phoneIcon';
import ChatIcon from '../icons/chatIcon';
import Red113Logo from '../red113Logo';
import style from './style.module.css';

const Header = () => {
  return (
    <header className={style.header}>
      <div className={style.headerInner}>
        <div className={style.leftColumn}>
          <a
            href="https://www.113.nl/"
            title="113.nl"
            className={style.red113Logo}
          >
            <Red113Logo />
          </a>
        </div>
        <div className={style.rightColumn}>
          <a href="tel:0800-0113" className={style.link}>
            <PhoneIcon className={style.phoneIcon} />
            <span>Bel gratis 0800-0113</span>
          </a>
          <a href="https://www.113.nl/chatten" className={style.link}>
            <ChatIcon className={style.chatIcon} />
            <span>Chat met ons</span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
