import { useState } from 'react';
import style from './style.module.css';
import ChevronUpIcon from '../icons/chevronUpIcon';
import CloseIcon from '../icons/closeIcon';
import PhoneIcon from '../icons/phoneIcon';
import ChatIcon from '../icons/chatIcon';

const MobileCallChatModal = () => {
  const [showModal, setShowModal] = useState(false);

  function handleShowModal() {
    setShowModal(true);
  }

  function handleHideModal() {
    setShowModal(false);
  }

  return (
    <div className={style.modalWrapper}>
      <div className={style.toggleButtonWrapper}>
        <button className={style.toggleButton} onClick={handleShowModal}>
          Bel of chat met ons
          <span className={style.buttonIconWrapper}>
            <ChevronUpIcon />
          </span>
        </button>
      </div>

      <div className={`${style.modal} ${showModal ? style.modalShow : ''}`}>
        <div className={style.modalHeader}>
          <h2>Denk je aan zelfmoord?</h2>
          <button className={style.closeButton} onClick={handleHideModal}>
            <div className={style.closeIconWrapper}>
              <CloseIcon />
            </div>
          </button>
        </div>

        <div className={style.modalBody}>
          <p>We zijn er voor je.</p>
          <p>Je kunt met ons geheel anoniem bellen en chatten.</p>
        </div>

        <div className={style.modalLinksWrapper}>
          <a className={style.modalLink} href="tel:0800-0113">
            <PhoneIcon className={style.phoneIcon} />
            Bel gratis 0800-0113
          </a>
          <a className={style.modalLink} href="https://www.113.nl/chatten">
            <ChatIcon className={style.chatIcon} />
            Chat met ons
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileCallChatModal;
