const ArrowDownIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 22"
      {...props}
    >
      <defs>
        <path id="a" d="M0 0h1440v765H0z" />
      </defs>
      <g fill="currentColor" fillRule="evenodd">
        <use fill="none" xlinkHref="#a" transform="translate(-771 -724)" />
        <path
          d="M-141-17H40c7.732 0 14 6.268 14 14v44h-209V-3c0-7.732 6.268-14 14-14z"
          fill="none"
        />
        <g fill="currentColor">
          <path d="M8 21.071L.929 14l.707-.707L8 19.657l6.364-6.364.707.707L8 21.071z" />
          <path d="M7.5 0h1v20h-1z" />
        </g>
      </g>
    </svg>
  );
};

export default ArrowDownIcon;
