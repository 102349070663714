const WarningIcon = (props) => {
  return (
    <svg
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.875 21.75L12.5 0.375L0.125 21.75H24.875ZM13.6254 18.375H11.3754V16.125H13.6254V18.375ZM11.3754 13.875H13.6254V9.375H11.3754V13.875Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningIcon;
