const PhoneIcon = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.91016 8.92969C5.5013 12.0781 7.88802 14.4648 11.0703 16.0898L13.457 13.7031C13.7617 13.3646 14.1341 13.2799 14.5742 13.4492C15.793 13.8555 17.0794 14.0586 18.4336 14.0586C18.7044 14.0586 18.9414 14.1602 19.1445 14.3633C19.3815 14.5664 19.5 14.8203 19.5 15.125V18.9336C19.5 19.2044 19.3815 19.4583 19.1445 19.6953C18.9414 19.8984 18.7044 20 18.4336 20C15.082 20 11.9844 19.1875 9.14062 17.5625C6.33073 15.9036 4.09635 13.6693 2.4375 10.8594C0.8125 8.01562 0 4.91797 0 1.56641C0 1.29557 0.101562 1.05859 0.304688 0.855469C0.541667 0.61849 0.795573 0.5 1.06641 0.5H4.875C5.17969 0.5 5.43359 0.61849 5.63672 0.855469C5.83984 1.05859 5.94141 1.29557 5.94141 1.56641C5.94141 2.92057 6.14453 4.20703 6.55078 5.42578C6.6862 5.86589 6.60156 6.23828 6.29688 6.54297L3.91016 8.92969Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PhoneIcon;
