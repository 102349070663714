import style from './style.module.css';
import WarningIcon from '../icons/warningIcon';

const HeadsUpText = () => {
  return (
    <div className={style.headsUpWrapper}>
      <div className={style.topLine}>
        <p className={style.bold}>Goed om te weten</p>
        <WarningIcon className={style.warningIcon} />
      </div>
      <p className={style.headsUpParagraph}>
        Jouw afbeelding wordt niet door ons opgeslagen. Na het uploaden bepaal
        jij of je de afbeelding wilt bewaren en delen.
      </p>
    </div>
  );
};

export default HeadsUpText;
